@font-face {
  font-family: 'dimon-icon';
  src:  url('fonts/dimon-icon.eot?ge3xd6');
  src:  url('fonts/dimon-icon.eot?ge3xd6#iefix') format('embedded-opentype'),
    url('fonts/dimon-icon.ttf?ge3xd6') format('truetype'),
    url('fonts/dimon-icon.woff?ge3xd6') format('woff'),
    url('fonts/dimon-icon.svg?ge3xd6#dimon-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="dimon-icon-"], [class*=" dimon-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'dimon-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dimon-icon-data1:before {
  content: "\e908";
}
.dimon-icon-data:before {
  content: "\e909";
}
.dimon-icon-human-resources:before {
  content: "\e900";
}
.dimon-icon-laptop:before {
  content: "\e901";
}
.dimon-icon-left-arrow:before {
  content: "\e902";
}
.dimon-icon-right-arrow:before {
  content: "\e90a";
}
.dimon-icon-presentation:before {
  content: "\e903";
}
.dimon-icon-strategy:before {
  content: "\e904";
}
.dimon-icon-target:before {
  content: "\e905";
}
.dimon-icon-training:before {
  content: "\e906";
}
.dimon-icon-visualization:before {
  content: "\e907";
}
