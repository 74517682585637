.site-footer {
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    background-color: rgb(255, 255, 255);
    box-shadow: 0px -1px 0px 0px rgba(239, 239, 239, 1);

    &__upper {
        padding-top: 120px;
        padding-bottom: 120px;
    }
}

.site-footer__bottom {
    padding-top: 50px;
    background-color: #fff9ff;
    .inner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-top: 44px;
        padding-bottom: 44px;
    }
}

.site-footer__copy {
    font-size: 16px;
    font-weight: 400;
    color: #74727a;
    margin: 0;

    a {
        color: $thm-base-color;
        transition: all .4s ease;

        &:hover {
            color: #fff;
        }
    }
}

.site-footer__social {
    display: flex;
    align-items: center;

    a {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background-color: $thm-base-color;
        text-align: center;
        line-height: 56px;
        color: #fff;
        background-size: 200% auto;
        font-size: 18px;
        transition: all .4s ease;

        +a {
            margin-left: 10px;
        }

        &:hover {
            background-position: right center;
        }

        &.fa-facebook-square {
            background-image: linear-gradient(40deg, rgb(255,169,92) 0%, rgb(255,67,192) 51%, rgb(255,169,92) 100%);

        }

        &.fa-twitter {
            background-image: linear-gradient(40deg, rgb(13,184,255) 0%, rgb(243,50,255) 51%, rgb(13,184,255) 100%);

        }

        &.fa-instagram {
            background-image: linear-gradient( 40deg, rgb(57,174,255) 0%, rgb(60,255,83) 51%, rgb(57,174,255) 100%);
        }

        &.fa-pinterest-p {
            background-image: linear-gradient( 40deg, rgb(255,112,62) 0%, rgb(255,236,78) 51%, rgb(255,112,62) 100%);
        }
    }
}


.footer-widget__links-wrap {
    @media (min-width: 1200px) {
        padding-right: 55px;
    }
}


.bottom-links{
    display: flex;
    align-items: center;
    justify-content: center;
    a{
        margin-right: 20px;
        &:last-child{
            margin-right: 0;
        }
    }
}

.bottom-links a{
    font-size: 16px;
    font-weight: 400;
    color: #74727a;
}

.footer-widget__title {
    font-size: 18px;
    font-weight: 500;
    color: $thm-black;
    margin: 0;
    line-height: 1em;
    margin-bottom: 40px;
}

.footer-widget__links {
    margin: 0;
    margin-top: -5px;

    li {
        line-height: 1em;

        +li {
            margin-top: 20px;
        }

        a {
            font-size: 16px;
            font-weight: 400;
            color: #74727a;
            transition: all .4s ease;

            &:hover {
                color: $thm-base-color-2;
            }
        }
    }
}

.footer-widget__contact {
    font-size: 16px;
    font-weight: 400;
    color: #74727a;
    margin: 0;
    line-height: 30px;

    a {
        color: inherit;
        transition: all .4s ease;

        &:hover {
            color: $thm-base-color;
        }
    }
}

.footer-widget__contact+.footer-widget__contact {
    margin-top: 15px;
}

.footer-widget__mailchimp {
    padding-left: 70px;
}

.footer-widget__logo {
    margin-bottom: 40px;
}


