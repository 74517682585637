body {
    font-family: $thm-font;
    color: $thm-text-color;
    font-size: 18px;
    line-height: 34px;
}

a:active,
a:hover,
a:focus,
a:visited {
    text-decoration: none;
}

.page-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 300px;
    overflow: hidden;
}

.thm-base-bg {
    background-color: $thm-base-color;
}

.thm-base-bg-2 {
    background-color: $thm-base-color-2;
}

.thm-gray-bg {
    background-color: $thm-gray;
}

@media(min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}


.block-title {
    margin-bottom: 45px;

    &__title {
        margin: 0;
        margin-top: -10px;
        font-size: 50px;
        font-weight: 400;
        color: $thm-black;
        line-height: 1.2em;
        span {
            font-weight: 700;
        }
    }
}


.thm-btn {
    border: none;
    display: inline-block;
    vertical-align: middle;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    color: $thm-black;
    padding: 13px 41px;
    border-radius: 5px;
    transition: background .4s ease, color .4s ease;
    background-image: linear-gradient(90deg, rgb(255, 78, 181) 0%, rgb(255, 160, 101) 100%);
    position: relative;



    &:before {
        content: '';
        position: absolute;
        top: 2px;
        right: 2px;
        bottom: 2px;
        left: 2px;
        border-radius: 5px;
        background-color: #fff;
        transform: scaleX(1);
        transition: transform .4s ease;
    }

    span {
        position: relative;
    }

    &:hover {
        color: #fff;

        &:before {
            transform: scaleX(0);
        }
    }
}

.post-pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        border-radius: 50%;
        background-color: $thm-gray;
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        padding: 8px 19px;
        color: $thm-black;
        transition: all .4s ease;

        &:hover,
        &.active {
            background-color: $thm-black;
            color: #fff;
        }

        +a {
            margin-left: 15px;
        }
    }
}

.preloader {
    position: fixed;
    background-color: #fff;
    background-position: center center;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9991;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &__image {
        animation-fill-mode: both;
        animation-name: flipInY;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }
}

.scroll-to-top {
    display: inline-block;
    width: 45px;
    height: 45px;
    background-image: linear-gradient(to right, rgb(255, 78, 181) 0%, rgb(255, 160, 101) 51%, rgb(255, 78, 181) 100%);
    background-size: 200% auto;
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 99;
    text-align: center;
    transition: .4s;
    display: none;
    border-radius: 50%;

    i {
        color: #fff;
        font-size: 18px;
        line-height: 45px;
    }

    &:hover {
        background-position: right center;
        i {
            color: #fff;
        }
    }


}