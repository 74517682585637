.fact-one {
	padding-top: 120px;
    .container {
        z-index: 10;
        position: relative;
    }

    &__single {
        box-shadow: 0px 20px 60px 0px rgba(42, 40, 51, 0.05);
        width: 220px;
        height: 220px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 10px;
            bottom: 10px;
            left: 10px;
            right: 10px;
            border-radius: 50%;
            background-image: linear-gradient(40deg, rgb(255, 67, 192) 0%, rgb(255, 169, 92) 100%);

        }
    }

    &__inner {
        width: 100%;
        height: 100%;
        box-shadow: 0px 20px 40px 0px rgba(255, 118, 142, 0.3);
        border-radius: 50%;
        position: relative;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h3,
        p {
            margin: 0;
        }

        h3 {
            color: $thm-black;
            font-weight: 400;
            font-size: 40px;
            line-height: 1em;
        }

        p {
            text-transform: uppercase;
            letter-spacing: .2em;
            font-size: 12px;
            font-weight: 500;
            color: #74727a;
            line-height: 1em;
            margin-top: 10px;
        }

    }

    [class*=col-]:nth-child(1) {
        .fact-one__inner {
            box-shadow: inset 0px 20px 40px 0px rgba(255, 118, 142, 0.3);
        }

        .fact-one__single {

            &:before {
                background-image: linear-gradient(40deg, rgb(255, 67, 192) 0%, rgb(255, 169, 92) 100%);

            }
        }
    }

    [class*=col-]:nth-child(2) {


        .fact-one__inner {
            box-shadow: inset 0px 20px 60px 0px rgba(141, 142, 255, 0.3);
        }

        .fact-one__single {

            &:before {
                background-image: linear-gradient(40deg, rgb(13, 184, 255) 0%, rgb(243, 50, 255) 100%);
                ;
            }
        }
    }

    [class*=col-]:nth-child(3) {

        .fact-one__inner {
            box-shadow: inset 0px 20px 40px 0px rgba(58, 212, 173, 0.3);
        }

        .fact-one__single {

            &:before {
                background-image: linear-gradient(40deg, rgb(57, 174, 255) 0%, rgb(60, 255, 83) 100%);
            }
        }
    }

    [class*=col-]:nth-child(4) {

        .fact-one__inner {

            box-shadow: inset 0px 20px 40px 0px rgba(255, 178, 71, 0.3);

        }

        .fact-one__single {

            &:before {
                background-image: linear-gradient(40deg, rgb(255, 112, 62) 0%, rgb(255, 236, 78) 100%);

            }
        }
    }
}